<template>
    <section>
        <Certificates class="mb-5"/>
        <CompletedCourses class="mb-5"/>
        <ActivityLog/>
    </section>
</template>

<script>
import Certificates from '@/components/user/profile/Certificates.vue'
import CompletedCourses from '@/components/teacher/course/CompletedCourses.vue'
import ActivityLog from '@/components/user/profile/ActivityLog.vue'
import { mapActions } from 'vuex'

export default {
    components: {
        Certificates,
        CompletedCourses,
        ActivityLog
    },
    methods: {
        ...mapActions('usr', ['getCourseCertificatesAction']),
    },
    mounted(){
        this.getCourseCertificatesAction()
    }
}
</script>