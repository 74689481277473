<template>
    <v-card class="custom-border border">
        <v-card-title class="d-flex flex-wrap align-center justify-space-between">
            <section class="d-flex flex-row align-center">
                <v-icon color="primary" class="pr-2">mdi-clock-outline</v-icon>
                <h4 class="primary-font secondary-1--text fw600">Activity Log</h4>
            </section>
            <section class="d-flex flex-row align-center justify-end ml-auto">
                <span class="poppins fw400 py-0 secondary-2--text f14 mr-2">
                    DATE: 
                </span>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    style="max-width: 600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            class="f14 ma-0 pa-0"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="() => {
                                $refs.menu.save(date)
                                menu = false
                            }"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </section>
        </v-card-title>
        <v-divider></v-divider>
       <v-sheet height="auto" max-height="50vh" class="d-flex overflow-y-auto flex-wrap ma-5 fade">
            <v-timeline
                align-top
                dense
                v-if="history.length > 0"
            >
                <v-timeline-item
                    v-for="(item, i) in history"
                    :key="i"
                    :color="item.revoked ? 'secondary' : 'primary'"
                    small
                    fill-dot
                    >
                    <div>
                        {{ item.revoked ? 'Log out' : 'Log in' }}
                    </div>
                    <div class="secondary-2--text text-uppercase fw500 f12" >{{ $dateFormat.mmDDyy(item.created_at) }} </div>
                </v-timeline-item>
            </v-timeline>

            <div class="fw400 py-0 secondary-2--text f14 mb-3" v-else>
                You have no login activities for {{ $dateFormat.mmDDyy(this.date) }}
            </div>
        </v-sheet>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data: () => ({
        history: [],
        menu: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),
    methods: {
        ...mapActions(['getLoginHistory']),
    },
    mounted(){
        this.getLoginHistory({ date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)}).then(res => {
            this.history = res
        })
    },
    watch: {
        menu(){
            if(!this.menu){
                this.getLoginHistory({ date_from: this.date, date_to: this.date}).then(res => {
                    this.history = res
                })
            }
        }
    }
}
</script>