<template>
    <v-card class="custom-border border">
        <v-card-title class="d-flex flex-row align-center">
            <v-icon color="primary" class="pr-2">mdi-book-check</v-icon>
            <h4 class="primary-font secondary-1--text fw600">Completed Courses</h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-sheet height="auto" max-height="50vh" class="d-flex overflow-y-auto flex-wrap pa-5 fade">
            <CoursesCard v-for="(course, i) in completed_courses" :key="i" :course="course" class="ma-3"/>
        </v-sheet>
    </v-card>
</template>


<script>
import { defineComponent } from '@vue/composition-api'
import CoursesCard from '@/components/user/profile/CoursesCard.vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
    components: {
        CoursesCard
    },
    computed: {
        ...mapState('usr', {
            completed_courses: (state) => state.completed_courses
        })
    },
    methods: {
        ...mapActions('usr', ['getCompletedCoursesAction'])
    },
    mounted(){
        this.getCompletedCoursesAction()
    }
})
</script>
