<template>
    <v-card class="custom-border border">
        <v-card-title class="d-flex flex-row align-center">
            <v-badge
                :content="certificates.length"
                :color="certificates.length === 0 ? 'transparent' : 'primary'"
                inline
            >
                <v-icon color="primary" class="pr-2">mdi-certificate-outline</v-icon>
                <h4 class="primary-font secondary-1--text fw600">My Certificates</h4>
            </v-badge>
        </v-card-title>
        <v-divider v-if="certificates.length > 0"></v-divider>
        <v-sheet height="auto" max-height="50vh" v-if="certificates.length>0" class="d-flex overflow-y-auto flex-wrap pa-5 fade">
            <CertificateCard v-for="(item, i) in certificates" :key="i" :certificate="item" class="ma-1 my-2" :class="$vuetify.breakpoint.mobile && 'mx-auto'"/>
        </v-sheet>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import CertificateCard from './CertificateCard.vue';

export default {
    components: {
        CertificateCard
    },
    computed: {
        ...mapState('usr', {
            certificates: (state) => state.certificates
        })
    }
}
</script>